import ArrowIcon from "../icons/arrow.icon";
import RenLink from "./RenLink";
import { prettyDate } from "../../helpers/dates";
import PropTypes from "prop-types";

const SimpleLink = ({ entry }) => {
  const { title } = entry;
  return (
    <RenLink href={entry}>
      <div className="c-simple-link">
        {title && <p className="c-simple-link__title">{title}</p>}
        {entry.byline && entry.byline.publishDate && (
          <p className="c-simple-link__category">
            <time dateTime={`${entry.byline.publishDate}`}>
              {prettyDate({ date: entry.byline.publishDate })}{" "}
            </time>
          </p>
        )}
        <section className="c-simple-link__arrow">
          <ArrowIcon />
        </section>
      </div>
    </RenLink>
  );
};

SimpleLink.propTypes = {
  entry: PropTypes.object,
};

export default SimpleLink;
