import SimpleLink from "./simple.link";
import PropTypes from "prop-types";
import { sortByByline } from "../../helpers/dates";

const SimpleLinkList = ({ entries }) => {
  sortByByline(entries);
  return (
    <div className="u-padding--bottom">
      {entries.map((entry, index) => (
        <SimpleLink entry={entry} key={entry.title + index} />
      ))}
    </div>
  );
};

SimpleLinkList.propTypes = {
  entries: PropTypes.array,
};

export default SimpleLinkList;
