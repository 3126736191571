import { prettyDate } from "../../helpers/dates";
import PropTypes from "prop-types";
const ArticleHeader = ({ title, lede, byline, created }) => (
  <header className="u-padding-top--larger">
    <div className="o-wrapper ">
      <div className="c-header__inner">
        <h1 className={"u-text--darkGreen u-header-title"}>{title}</h1>
        {lede && <p className="u-padding-bottom u-fontLead">{lede}</p>}
        <div className="u-padding-top-bottom--small u-fontSmall">
          {created && (
            <p className="u-margin--rm u-padding-bottom--small">
              Publisert {prettyDate({ date: created })}
            </p>
          )}
          {byline && (
            <div className="">
              <p className="u-margin--rm u-roboto500">
                {byline.bylineName || ""}
              </p>
              <p className="u-margin--rm">{byline.bylineTittel || ""}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  </header>
);
ArticleHeader.propTypes = {
  created: PropTypes.string,
  title: PropTypes.string,
  lede: PropTypes.string,
  byline: PropTypes.object,
  yellow: PropTypes.bool,
};
export default ArticleHeader;
