function splitArticles(articles, headlineTags) {
  const headline = [];
  const reduced = [];
  articles.map(article => {
    const { tags = [] } = article;
    tags.some(tag => headlineTags.some(t => t.title == tag.title))
      ? headline.push(article)
      : reduced.push(article);
  });
  return [headline, reduced];
}

function checkForURLTags(router, globalTags, setHeroTags) {
  router.query.tags &&
    setHeroTags(
      router.query.tags
        .split("|")
        .map(tag => globalTags.find(gTag => gTag.title == tag))
    );
}

export { splitArticles, checkForURLTags };
